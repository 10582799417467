import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    FormGroup,
    Backdrop,
    CircularProgress,
    Box,
} from '@mui/material';
import ApiIcon from '@mui/icons-material/Api';
import { postJson } from './network';

export default function CosineMatch(props) {
    const [topicDescription, setTopicDescription] = useState('');
    const [callTurnTranscript, setCallTurnTranscript] = useState('');
    const [loading, setLoading] = useState(false);
    const [cosineMatchResult, setCosineMatchResult] = useState(null);


    const handleOnclickForSimilarity = async (e) => {
            if (!topicDescription || !callTurnTranscript) {
                alert("Both Topic Description and Call Turn Transcript cannot be empty");
                setGetCosineOutput(false);
                return;
            }
            setLoading(true);
                try {
                    var data = await postJson('/similarity/cosine-match', {
                        topicDescription: topicDescription,
                        callTurnTranscript: callTurnTranscript
                    });
    
                    data = await data.json();
                    setCosineMatchResult(data.cosineMatch);
                } catch (e) {
                    alert('Error fetching cosine match results: ' + e.message);
                }
                setLoading(false);
    }

    return (
        <div style={{ paddingRight: '20px', width: '100%' }}>
            <Backdrop open={loading} style={{ zIndex: '5000' }}>
                <CircularProgress />
            </Backdrop>
            
            {/* Add heading */}
            <h2>Cosine Match between 2 strings</h2>

            <FormGroup>
                <Box display="flex" gap="20px" justifyContent="space-between">
                    {/* Text box for Custom Topic Description */}
                    <Box flex={1} border="1px solid #ccc" padding="10px" borderRadius="5px">
                        <TextField
                            fullWidth
                            value={topicDescription}
                            onChange={(e) => setTopicDescription(e.target.value)}
                            label="Custom Topic Description"
                            multiline
                            rows={10} 
                            inputProps={{ maxLength: 5000 }}
                            variant="outlined"
                        />
                    </Box>

                    {/* Text box for Call Turn Transcript */}
                    <Box flex={1} border="1px solid #ccc" padding="10px" borderRadius="5px">
                        <TextField
                            fullWidth
                            value={callTurnTranscript}
                            onChange={(e) => setCallTurnTranscript(e.target.value)}
                            label="Call Turn Transcript"
                            multiline
                            rows={10} 
                            inputProps={{ maxLength: 5000 }} 
                            variant="outlined"
                        />
                    </Box>
                </Box>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '20px'
                    }}
                >
                    <Button
                        style={{ width: '100%', marginBottom: '0.7rem' }}
                        variant="contained"
                        color="primary"
                        onClick={handleOnclickForSimilarity}
                        startIcon={<ApiIcon />}
                    >
                        Calculate Embeddings Cosine Match
                    </Button>
                </div>

                {cosineMatchResult && (
                    <div>
                        <h3>Cosine Match Result:</h3>
                        <p>{cosineMatchResult}</p>
                    </div>
                )}
            </FormGroup>
        </div>
    );
}
